<template>
  <div>
    <div class="calendar-actions">
      <base-button
          size="sm"
          outline
          type="primary"
          class="calendar-action-button"
          @click="copyIframeLink()"
      ><i class="fa fa-link" />{{$t("calendar.publicCalendar")}}</base-button
      >
    </div>
    <!-- Branding Section -->
    <base-header
      class="pb-6 pt-3 content__title content__title--calendar"
      type="base"
    ></base-header>

    <base-calendar />
  </div>
</template>

<script>
// packages
import formMixin from "@/mixins/form-mixin";
// components
import BaseCalendar from "@/components/Calendar/BaseCalendar.vue";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
export default {
  name: "calendar",
  components: {
    BaseCalendar,
  },
  mixins: [formMixin],
  data() {
    return {
      user: null,
    };
  },
  created() {
    this.auth();
  },
  methods: {   
    async auth() {
      try {
        // Fetch User data
        //await this.$store.dispatch("profile/me");
        this.user = await { ...this.$store.getters["profile/me"] };
      } catch (error) {
        // Notification
        this.$notify({
          type: "danger",
        message: this.$t('general.oopsSomethingWentWrongWithYourAccount')+ '!',
        });
        this.setApiValidation(error.response.data.errors);
      }
    },


    async copyIframeLink() {      

      if (this.user) {
        const publicURL = process.env.VUE_APP_BASE_URL +
            '/calendar/' +
            this.user.uuid;
         const iFrameCodeblock =
          '<iframe src=' + publicURL +
          ' width=100%; height=900em; style=border:none;' +
          '></iframe>';
        const copyFirstLink = "'function copyURL(){ navigator.clipboard.writeText(\""+iFrameCodeblock+"\").then(function(){alert(\"Calendar as iFrame - Copied, ready to share it!\")},function(){alert(\"Calendar iFrame link copy failed!\")}); };copyURL()'"
        const copySecondLink = "'function copyURL(){ navigator.clipboard.writeText(\""+publicURL+"\").then(function(){alert(\"Calendar as Direct Link - Copied, ready to share it!!\")},function(){alert(\"Calendar direct link copy failed!\")}); };copyURL()'"

        swal.fire({
            title: this.$t('calendar.publicCalendarLink'),
            html:
              this.$t('calendar.youCanUseTheBelowIframeOrDirectLinkToIncorporateTheEntireCalendar') + "<br>" + "</hr> " +
              "<br><b>"+ this.$t('calendar.asEmbedIframe')+":</b><br> <button onclick="+copyFirstLink+"type='button' class='btn-icon-clipboard text-center'>" +
              "<'" + "iframe src='https://app.bookmeinscotty.com/...' width='100%' height='900em' style='border:none;'" + '><' + "'/iframe'" + ">" +
              "</button> <br><b>"+ this.$t('calendar.orByDirectLink')+":</b></hr></b><br> <span onclick="+copySecondLink+" type='button' class='btn-icon-clipboard text-center'>" +
                publicURL +
                "</span> <br></hr> <small class='text-muted text-uppercase text-xs text-black-50'> <i class='ni ni-single-copy-04'/>"+ this.$t('calendar.clickToCopy') + "</small>" ,
            // text: this.iFrameCodeblock,
            type: "success",
            confirmButtonClass: "btn btn-outline-danger btn-fill",
            confirmButtonText: this.$t('general.close'),
            buttonsStyling: false,
            icon: "success",
          });
        // try {
        //   await navigator.clipboard.writeText(iFrameCodeblock);
        //   swal.fire({
        //     title: "Public Calendar Link",
        //     html:
        //       "You can use the below iframe or direct link to incorporate the entire Calendar to any website, your social media or as web widget etc.<br></hr> " +
        //       "<br><b>as embed iFrame:</b><br> <button onclick="+copyFirstLink+"type='button' class='btn-icon-clipboard text-center'>" +
        //       "<'" + "iframe src='https://app.bookmeinscotty.com/...' width='100%' height='580em' style='border:none;'" + '><' + "'/iframe'" + ">" +
        //       "</button> <br> <b>or by direct Link:</b></hr></b><br> <span onclick="+copySecondLink+" type='button' class='btn-icon-clipboard text-center'>" +
        //         publicURL +
        //         "</span> <br></hr>" ,
        //     // text: this.iFrameCodeblock,
        //     type: "success",
        //     confirmButtonClass: "btn btn-outline-danger btn-fill",
        //     confirmButtonText: " Close",
        //     buttonsStyling: false,
        //     icon: "success",
        //   });
        // } catch ($e) {
        //   alert("Cannot copy");
        // }
      }
    },
  },
};

</script>
